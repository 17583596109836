<template>
	<div class="ysm-order-one">
		<!-- 患者信息 -->
		<div align="left" class="patientLeft">
				<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
				<div>
					<h3 data-i18n="[html]forms.order.patient_information">患者信息</h3>
					<p data-i18n="forms.order.patient_information_content">请在此处输入患者信息</p>
				</div>
		</div>
		<div class="patientRIght">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-input v-model="ruleForm.doctorId" v-show="false"></el-input>
				<el-input v-model="ruleForm.productId" v-show="false"></el-input>
				<el-form-item label="患者编号" prop="patientNum">
					<el-input v-model="ruleForm.patientNum" placeholder="请输入患者编号"></el-input>
				</el-form-item>
				<el-form-item prop="patientBirthday" label="出生年份">
					<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="选择日期" v-model="ruleForm.patientBirthday" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="性别" prop="patientGender">
					<!-- <el-radio-group v-model="ruleForm.patientGender">
						<el-radio class="sex" label="男"></el-radio>
						<el-radio class="sex" label="女"></el-radio>
					</el-radio-group> -->
					<el-select style="float: left;" v-model="ruleForm.patientGender" placeholder="请选择">
					   <el-option
					     v-for="item in sexs"
					     :key="item.value"
					     :label="item.label"
					     :value="item.value">
					   </el-option>
					 </el-select>
					
				</el-form-item>
				<el-form-item style="float: left;position:relative;top: -25px;">
					*为必填字段
				</el-form-item>
				<el-form-item style="text-align: center;clear: both;position:relative;top: -25px;">
					<el-button class="nextStep" type="primary" @click="submitForm('ruleForm')">下一步</el-button>
					<el-button class="chongzhi" @click="resetForm('ruleForm')">重&nbsp;&nbsp;&nbsp;置</el-button>
				</el-form-item>
			</el-form>
			
		</div>
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				sexs:[
					{
						value: '男',
						label: '男'
					},
					{
						value: '女',
						label: '女'
					}
					
				],
				ruleForm: {
					doctorId: '',
					productId: 2,
					patientNum: '',
					patientGender: '',
					patientBirthday: '',
					oid:"0"
				},
				rules: {
					patientNum: [{
							required: true,
							message: '请输入患者编号',
							trigger: 'blur'
						}
					],
					patientGender: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					patientBirthday: [{
						// type: 'date',
						required: true,
						message: '请选择出生日期',
						trigger: 'blur',
						
					}]
				}
			}
		},
		methods:{
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.ruleForm.doctorId==""||this.ruleForm.doctorId==0||this.ruleForm.doctorId==null){
							this.ruleForm.doctorId=this.$store.state.doctorId;
						}
						this.$axios.post("client/order/addOrder",JSON.stringify(this.ruleForm),{
							headers:{
								"Content-type":"application/json"
							}
						}).then(res=>{
							sessionStorage.setItem("oid",res.data.data);
							this.$store.commit("setYsmStepIndex",2);
							this.$router.push("/ysmmain/ysmorder/ysmordertwo");
						}).catch(err=>{
							this.$message.error("添加失败");
						})
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		mounted:function(){
			this.$store.commit("setYsmStepIndex",1);
			this.ruleForm.doctorId=sessionStorage.getItem("doctorId");
			this.$store.commit("setBackLocation","/ysmmain/ysmlatestorder");
		},
		created:function(){
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3 visible")	
		document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
		document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
		document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2 ")
		
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			console.log(oid);
			if(oid!="undefined"&&oid!=''&&oid!=null){
				console.log(111);
				this.ruleForm.oid=oid;
				//根据oid查询订单信息
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res);
					let orderInfo=res.data.data;
					if(orderInfo.patientNum!=null){
						this.ruleForm.patientNum=orderInfo.patientNum
					}
					if(orderInfo.patientGender!=null){
						this.ruleForm.patientGender=orderInfo.patientGender
					}
					if(orderInfo.patientBirthday!=null){
						this.ruleForm.patientBirthday=orderInfo.patientBirthday
					}
					
				}).catch(err=>{
					console.log(err);
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.ysm-order-one{
	/* 	width: 90%;
		position: relative;
		display: flex;
		justify-content: space-around; */
		margin: 0px auto;
		width: 80%;
		display: flex;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
		
	}
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.patientLeft{
		display: flex;
		width: 30rem;
		justify-content: flex-start;
	}
	.ysm-order-one{
		display: flex;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		position: relative;
		justify-content: space-around;
	}
	/* 右边 */
	.patientRIght{
		width: 560px;
		
	}
	.nextStep{
		/* background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important; */
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.chongzhi{
		min-width: 7.5rem;
	}
	
</style>
